import {
  LocationClient,
  // SearchPlaceIndexForTextCommand,
  SearchPlaceIndexForSuggestionsCommand,
} from '@aws-sdk/client-location';
import { withAPIKey } from '@aws/amazon-location-utilities-auth-helper';
// SearchSearchPlaceIndexForSuggestions
const apiKey =
  'v1.public.eyJqdGkiOiI1Y2Y3MDQxMi1lZGEzLTQxMWUtYmEyZC02NzIyY2RlY2ZlOGQifUcOhjlh1fXQyjL9waDQa4vKxzq7JiwZ63zqVukVfSs9JazQhpxTNB71-8CQo_RqElHbS2J6GcLQEAeonpUHTRzTWxn7WXK4ziOmhbPBpNx3wvRxGLCKKGRrBnEXNAPxySp5SPiakelSd7p4EV0aa3wgied7Hy3wty0t6q3fHFKXLGA7nP2UIXm2iR9V5M2SWdziQ8TXTeL6xPjgwvnF5emfL_tl8mbS-TAU9rlXOiGUFZQUfGav6OFxLjeDP6xOvD-F4HFbF6IA-aRQnUqIH06HWfFRUcIPhtQqi0BlBzcFbDqhFTGZnQRhAGYbS8yPCqJvehxXc3AeZ0Xz1yM4ceY.ZWU0ZWIzMTktMWRhNi00Mzg0LTllMzYtNzlmMDU3MjRmYTkx'; // API key

export const getLocation = async (str, latLong) => {
  const authHelper = await withAPIKey(apiKey);

  const client = new LocationClient({
    region: 'us-east-1', // region containing Cognito pool
    ...authHelper.getLocationClientConfig(), // Provides configuration required to make requests to Amazon Location
  });

  const input = {
    IndexName: 'Dev',
    Text: str,
    // FilterCategories: ['AddressType'],
    MaxResults: 6,
    FilterCountries: ['USA'],
    Language: 'en',
    // FilterBBox: [-12.7935, -37.4835, -12.0684, -36.9542],
  };
  if (latLong) input.BiasPosition = latLong;
  const command = new SearchPlaceIndexForSuggestionsCommand(input);

  const response = await client.send(command);
  console.log({ response });
  return response;
};
