export const styles = {
  label: {
    width: 110,
    color: 'grey',
    textTranform: 'capitalize',
  },
  modal: {
    minWidth: 770,
  },
  input: {
    width: 'calc(100% - 110px)',
  },
  select: {
    width: 'calc(100% - 110px)',
  },
  contentBox: {
    display: 'flex',
    flexDirection: 'column',
    gap: 3,
  },
  modalTitle: {
    fontSize: 20,
    fontWeight: 500,
  },
  loadingBox: {
    minHeight: 292,
    minWidth: 400,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
};
