import {
  TextField,
  IconButton,
  Typography,
  Box,
  Button,
  Select,
  MenuItem,
  FormControl,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  FormHelperText,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

export default function FormDevices({
  devices,
  onChangeDevice,
  setDevices,
  products,
  prices,
  sameErrs,
}) {
  const removeDevice = (i) => {
    const copy = [...devices];
    copy.splice(i, 1);
    setDevices(copy);
  };
  return (
    <>
      <Box display="flex" flexDirection="column">
        <Box sx={{ width: 240 }}>
          <Typography variant="overline">Devices:</Typography>
        </Box>
        <TableContainer sx={{ border: '1px solid #cccccc', borderRadius: 1 }}>
          <Table aria-label="table for SKU" size="small">
            <TableHead>
              <TableRow>
                <TableCell>SKU</TableCell>
                <TableCell align="left">Quantity</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {devices.map((device, i) => (
                <TableRow
                  key={device.sku}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row" sx={{ width: '50%' }}>
                    <FormControl fullWidth size="small">
                      <Select
                        value={devices[i].sku}
                        placeholder="Select"
                        onChange={(e) => onChangeDevice(e, i)}
                        name="sku"
                        error={sameErrs[i]}
                      >
                        {Object.keys(products).length &&
                          Object.keys(products).map((sku) => {
                            // remove selected devices from the list
                            return (
                              <MenuItem value={sku} key={sku}>
                                {sku}
                              </MenuItem>
                            );
                          })}
                      </Select>
                    </FormControl>
                    {sameErrs[i] && (
                      <FormHelperText
                        htmlFor="device-select"
                        error={sameErrs[i]}
                      >
                        The SKU already selected
                      </FormHelperText>
                    )}
                  </TableCell>
                  <TableCell align="left">
                    <TextField
                      name="quantity"
                      placeholder={device.label}
                      fullWidth
                      size="small"
                      type="number"
                      InputProps={{ inputProps: { min: 0 } }}
                      sx={{
                        width: 'auto',
                        marginBottom: sameErrs[i] ? '20px' : 0,
                      }}
                      onChange={(e) => onChangeDevice(e, i)}
                    />
                  </TableCell>
                  <TableCell>
                    { devices.length > 0 && (
                      <IconButton
                        onClick={() => removeDevice(i)}
                        aria-label={`delete this device`}
                      >
                        <DeleteIcon
                          color="gray.400"
                          sx={{
                            marginBottom: sameErrs[i] ? '20px' : 0,
                            marginTop: '5px',
                          }}
                        />
                      </IconButton>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <Box>
        <Button
          variant="outlined"
          size="small"
          mt={2}
          sx={{ float: 'right', width: 200 }}
          onClick={() => setDevices(devices.concat([{ sku: '', quantity: 0 }]))}
        >
          Add SKU
        </Button>
      </Box>
    </>
  );
}
