import { TableCell, Chip } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';

const UserRoleCell = (props) => {
  const { roles } = props;
  return (
    <TableCell>
      {roles.map((role, i) => {
        return (
          <Chip
            key={role}
            icon={<PersonIcon />}
            label={role}
            color={role === 'admin' ? 'error' : 'info'}
            sx={{
              marginRight: i !== roles.length - 1 ? 1 : 0,
              textTransform: 'capitalize',
            }}
          />
        );
      })}
    </TableCell>
  );
};

export default UserRoleCell;
