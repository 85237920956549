import { createContext, useReducer, useContext } from 'react';

export const GlobalContext = createContext(null);
export const GlobalDispatchContext = createContext(null);

function globalReducer(state, action) {
  switch (action.type) {
    case 'SET_IS_LOADING': {
      return {
        ...state,
        isLoading: action.payload,
      };
    }
    case 'SET_IS_DEVICE_LOADING': {
      return {
        ...state,
        isDeviceLoading: action.payload,
      };
    }
    case 'SET_IS_ORDER_LOADING': {
      return {
        ...state,
        isOrderLoading: action.payload,
      };
    }
    case 'SET_DEVICES': {
      return {
        ...state,
        devices: action.payload,
      };
    }
    case 'SET_ORDERS': {
      return {
        ...state,
        orders: action.payload,
      };
    }
    case 'SET_ACCOUNT': {
      return {
        ...state,
        account: action.payload,
      };
    }
    case 'SET_API_KEY': {
      return {
        ...state,
        apiKey: action.payload,
      };
    }
    case 'SET_TOKEN': {
      return {
        ...state,
        token: action.payload,
      };
    }
    case 'SET_POLICIES': {
      return {
        ...state,
        policies: action.payload,
      };
    }
    // case 'deleted': {
    //   return tasks.filter(t => t.id !== action.id);
    // }
    default: {
      throw Error('Unknown action: ' + action.type);
    }
  }
}

const initialStates = {
  isLoading: false,
  isDeviceLoading: true,
  isOrderLoading: true,
  devices: [],
  orders: [],
  account: null,
  apiKey: { name: '', key: '' },
  token: '',
  policies: { admin: {}, prod: {}, dev: {} },
};

function GlobalProvider({ children }) {
  const [state, dispatch] = useReducer(globalReducer, initialStates);

  const setAccount = (account) => {
    dispatch({ type: 'SET_ACCOUNT', payload: account });
    // if authenticated user is admin
    const authUserPolicyDoc = account.authenticated_user.policy_document;
    const keys = Object.keys(authUserPolicyDoc);
    const arr = keys.map((key) =>
      authUserPolicyDoc[key].includes('admin:CreateUser')
    );
    if (arr.flat().includes(true)) {
      const adminKey = keys.find((key) =>
        authUserPolicyDoc[key].includes('admin:CreateUser')
      );
      const adminPolicy = { [adminKey]: authUserPolicyDoc[adminKey] };
      const devPolicyArr = account.pylo_accounts.map((item) => {
        return { name: item.name, key: item.api_key };
      });
      const devPolicies = devPolicyArr.map((item) => {
        return {
          name: item.name,
          data: {
            [item.key]: authUserPolicyDoc[item.key],
          },
        };
      });

      const obj = {
        Admin: adminPolicy,
      };
      devPolicies.forEach((policy) => {
        obj[policy.name] = policy.data;
      });
      dispatch({
        type: 'SET_POLICIES',
        payload: obj,
      });
    }
  };

  const setApiKey = (obj) => {
    dispatch({
      type: 'SET_API_KEY',
      payload: { name: obj.name, key: obj.key },
    });
  };
  const setIsDeviceLoading = (state) => {
    dispatch({
      type: 'SET_IS_DEVICE_LOADING',
      payload: state,
    });
  };
  const setIsOrderLoading = (state) => {
    dispatch({
      type: 'SET_IS_ORDER_LOADING',
      payload: state,
    });
  };
  const setToken = (value) => {
    dispatch({
      type: 'SET_TOKEN',
      payload: value,
    });
  };
  const setOrders = (value) => {
    dispatch({
      type: 'SET_ORDERS',
      payload: value,
    });
  };
  const setDevices = (value) => {
    dispatch({
      type: 'SET_DEVICES',
      payload: value,
    });
  };
  const setPolicies = (obj) => {
    dispatch({
      type: 'SET_POLICIES',
      payload: obj,
    });
  };
  const setIsloading = (state) => {
    dispatch({
      type: 'SET_IS_LOADING',
      payload: state,
    });
  };
  return (
    <GlobalContext.Provider
      value={{
        state,
        setAccount,
        setApiKey,
        setIsDeviceLoading,
        setToken,
        setIsOrderLoading,
        setOrders,
        setDevices,
        setPolicies,
        setIsloading,
      }}
    >
      <GlobalDispatchContext.Provider value={dispatch}>
        {children}
      </GlobalDispatchContext.Provider>
    </GlobalContext.Provider>
  );
}
const useGlobalContext = () => {
  return useContext(GlobalContext);
};
export { GlobalProvider, useGlobalContext };
