export const formItems = [
  { label: 'Recipient first name', name: 'fname' },
  { label: 'Recipient last name', name: 'lname' },
  { label: 'Recipient phone number', name: 'phone' },
  { label: 'Recipient email', name: 'email' },
  { label: 'Recipient company', name: 'company' },
  { 
    label: 'Patient ID (optional)', 
    name: 'patient_identifier', 
    subText: "Adds an identifier to the order and its related devices intended to identify the patient.",
    required: false,
  },
  { 
    label: 'Practice ID (optional)', 
    name: 'practice_identifier', 
    subText: "Adds an identifier to the order and its related devices intended to identify the practice.",
    required: false,
  },
];
export const formAddress = [
  { label: 'Street address', name: 'address1' },
  { label: 'Apt, suite, etc (optional)', name: 'address2' },
  { label: 'City', name: 'city' },
  { label: 'State', name: 'state' },
  { label: 'Postal code', name: 'pcode' },
  { label: 'Country code', name: 'ccode' },
];

export const shippingOptions = [
  {
    label: 'Shipping Service',
    name: 'shipping',
    list: ['Standard', '2-Day shipping'],
  },
  { label: 'Require signature?', name: 'signature', list: ['Yes', 'No'] },
];

export const countryCode = [{ USA: 'US' }];

export const orderErrMsg = {
  title: 'The order was not placed.',
  msg: 'Please try again or contact our customer support.',
  link: '/orders',
  linkText: 'Go to order list',
};
