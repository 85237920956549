import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';
import Layout from '../mui-components/Layout';
import { Link, Stack, Typography } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { pageTitles } from '../data/pageTitles';

export default function OrderPlaced() {
  document.title = pageTitles.orderPlaced;
  const navigate = useNavigate();
  const { id } = useParams();

  return (
    <Layout title="">
      <Stack alignItems="center">
        <CheckCircleIcon color="success" sx={{ fontSize: 60 }} />
        <Typography variant="h2" mb={4}>
          Order Placed Successfully
        </Typography>
        <Typography variant="body1">
          Click below to see he order details.
        </Typography>
        <Link onClick={() => navigate('/orders/'.concat(id))}>
          Order ID: {id}
        </Link>
      </Stack>
    </Layout>
  );
}
