import { Box } from '@mui/material';

const PolicyList = (props) => {
  const { list } = props;
  return (
    <Box>
      <ul>
        {list.map((policy) => {
          return <li key={policy}>{policy}</li>;
        })}
      </ul>
    </Box>
  );
};

export default PolicyList;
