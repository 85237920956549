import { useParams } from 'react-router';
import Layout from '../mui-components/Layout';
import { pageTitles } from '../data/pageTitles';
import { useGlobalContext } from '../context/GlobalStore';

export default function Order() {
  document.title = pageTitles.order;
  const { id } = useParams();
  const { state } = useGlobalContext();
  const order = state.orders.filter((o) => o.order_number === id)[0];

  return (
    <Layout title="Order Details">
      <div className="col-75">
        <p>Order Number: {order.order_number}</p>
        <p>Order Status: {order.order_status}</p>
        <p>Date Placed: {order.date_placed}</p>
        <p>Tracking Number: {order.tracking_number}</p>
        <p>Recipient First Name: {order.first_name_recipient}</p>
        <p>Recipient Last Name: {order.last_name_recipient}</p>
        <p>Address: {order.address1}</p>
        <p>City: {order.city}</p>
        <p>State:{order.state}</p>
        <p>Postal Code:{order.postal_code}</p>
        <p>Patient Id: {order.patient_id}</p>
        <p>Practice Id: {order.practice_id}</p>
      </div>
    </Layout>
  );
}
