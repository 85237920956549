import { useEffect, useState } from 'react';
import { post } from 'aws-amplify/api';
import { GridToolbar } from '@mui/x-data-grid';
import { NoRowsGrid, NoResultsGrid } from '../mui-components/NoRowsGrid';
import { Typography, Stack } from '@mui/material';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { addIds } from '../utils/helpers';
// import ErrorBoundary from '../mui-components/ErrorBoundary';
// import ErrorGenericFallback from '../mui-components/ErrorGenericFallback';
import moment from 'moment';
import { timeFormat } from '../data/formats';
import Loading from '../mui-components/Loading';

const columns = [
  { field: 'Id', headerName: 'Measurement Id', width: 130 },
  { field: 'MeasurementType', headerName: 'Measurement Type', width: 160 },
  {
    field: 'MeasuredAt',
    headerName: 'Measured At',
    width: 200
  },
  { field: 'DeviceId', headerName: 'Device Id', width: 100 },
  { field: 'DeviceModel', headerName: 'Device Model', width: 130 },
  { field: 'DeviceType', headerName: 'Device Type', width: 130 },
  { field: 'DeviceSim', headerName: 'Device Sim', width: 130 },
  {
    field: 'SignalStrengthPercentage',
    headerName: 'Signal Strength',
    width: 180,
  },
  {
    field: 'RadioAccessTechnology',
    headerName: 'Radio Access Technology',
    width: 200,
  },
  { field: 'NetworkName', headerName: 'Network Name', width: 120 },
  { field: 'TowerLongitude', headerName: 'Tower Longitude', width: 130 },
  { field: 'TowerLatitude', headerName: 'Tower Latitude', width: 130 },
];

export default function Readings({ token, id, accountId }) {
  const [readings, setReadings] = useState([]);
  const [isLoading, setIsloading] = useState(true);

  useEffect(() => {
    getReadings();
  }, []);

  async function getReadings() {
    try {
      const restOperation = post({
        apiName: 'pylo',
        path: '/pylo/readings',
        options: {
          body: {
            access_token: token.toString(),
            imei: id,
            account_id: accountId,
          },
        },
      });
      const { body } = await restOperation.response;
      const response = await body.json();

      if (response.length > 0) {
        const rows = addIds(response);
        setReadings(rows);
      }
    } catch (e) {
      console.log('POST call failed: ', JSON.parse(e.response.body));
    } finally {
      setIsloading(false);
    }
  }

  return (
    <Stack sx={{ height: 'calc(100vh - 400px)' }} mt={2}>
      <Typography variant="h5" mb={1} color="grey">
        Readings
      </Typography>
      {isLoading ? (
        <Loading />
      ) : (
        <DataGridPro
          rows={readings}
          columns={columns}
          slots={{
            toolbar: GridToolbar,
            noRowsOverlay: NoRowsGrid,
            noResultsOverlay: NoResultsGrid,
          }}
          pagination={true}
          sx={{
            '& .MuiDataGridPro-overlay': { minHeight: 300 },
            '& .MuiDataGrid-overlayWrapper': { minHeight: 300 },
          }}
        />
      )}
    </Stack>
  );
}
