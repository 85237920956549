import WarningIcon from '@mui/icons-material/Warning';
import Layout from '../mui-components/Layout';
import { Stack, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

const ContactSupport = () => {
  const supportEmailAddress = 'support@prevounce.com';
  const mailTo = 'mailto:';
  return (
    <Layout isSideMenu={false}>
      <Stack
        spacing={5}
        sx={{
          width: '100%',
          height: 400,
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <WarningIcon sx={{ width: 100, height: 100 }} color="warning" />
        <Typography variant="h1" maxWidth={600}>
          An unexpected error occurred.
        </Typography>
        <Typography variant="body1">
          Please refresh the page and try again. Or please contact our customer
          support.
        </Typography>
        <Link
          to="#"
          onClick={(e) => {
            window.location.href = mailTo + supportEmailAddress;
            e.preventDefault();
          }}
        >
          Email: {supportEmailAddress}
        </Link>
      </Stack>
    </Layout>
  );
};

export default ContactSupport;
