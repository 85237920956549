export const policyTemplates = {
  admin: {
    '28d3dbac-2ac1-4bca-b023-5ca5a6480c62': [
      'admin:CreateUser',
      'admin:DeleteUser',
      'admin:ListUsers',
    ],
    '2CSnCar8V24CA2gIOQAcz8oKxrwFCDCak5bKs1U8': [
      'pylo:PlaceOrder',
      'pylo:GetDevices',
      'pylo:GetOrders',
      'pylo:GetReadings',
    ],
  },
  dev: {
    '5CtBo3JQOj2xAVfsi57om6LNjWgvSqvf3V5EXkVE': [
      'pylo:GetDevices',
      'pylo:GetOrders',
      'pylo:GetReadings',
    ],
  },
};

export const policies = {
  admin: {
    list: ['Admin: Create User', 'Admin: Delete User', 'Admin: List Users'],
    id: '28d3dbac-2ac1-4bca-b023-5ca5a6480c62',
  },
  dev: {
    list: [
      'Dev: Place Order',
      'Dev: Get Devices',
      'Dev: Get Orders',
      'Dev: Get Readings',
    ],
    id: '5CtBo3JQOj2xAVfsi57om6LNjWgvSqvf3V5EXkVE',
  },
};

export const selectItems = [
  { label: 'User Role', name: 'role', list: ['Admin', 'Developer'] },
  { label: 'App Access', name: 'access' },
];
