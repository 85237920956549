import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import '@aws-amplify/ui-react/styles.css';
import { ThemeProvider as ThemeProviderAWS } from '@aws-amplify/ui-react';
import { studioTheme } from './ui-components';
import { Amplify } from 'aws-amplify';
import awsExports from './aws-exports';
import { ThemeProvider } from '@mui/material/styles';
import theme from './theme';
import { GlobalProvider } from './context/GlobalStore';

Amplify.configure(awsExports);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ThemeProviderAWS theme={studioTheme}>
      <ThemeProvider theme={theme}>
        <GlobalProvider>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </GlobalProvider>
      </ThemeProvider>
    </ThemeProviderAWS>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
