import { useGlobalContext } from '../context/GlobalStore';

import { CircularProgress, Backdrop, Typography } from '@mui/material';

const LoadingBackdrop = () => {
  const { state } = useGlobalContext();
  return (
    <Backdrop
      sx={{
        color: '#fff',
        zIndex: (theme) => theme.zIndex.drawer + 1,
        flexDirection: 'column',
      }}
      open={state.isLoading}
    >
      <CircularProgress color="inherit" size={100} />
      <Typography color="inherit" mt={6} variant="h6">
        Loading...
      </Typography>
    </Backdrop>
  );
};

export default LoadingBackdrop;
