import { useCallback } from 'react';
import { get, post } from 'aws-amplify/api';
import { useGlobalContext } from '../context/GlobalStore';
import { sortedOrders } from '../utils/queries';

const useFetchOrders = () => {
  const { setIsOrderLoading, setOrders } = useGlobalContext();
  const fetchData = useCallback(
    async (accessToken, accountId) => {
      try {
        const restOperation = get({
          apiName: 'pylo',
          path: '/pylo/orders',
          options: {
            queryParams: {
              access_token: accessToken.toString(),
              account_id: accountId,
            },
          },
        });
        const { body } = await restOperation.response;
        const response = await body.json();
        if (response) {
          const sorted = sortedOrders(response);

          setOrders(sorted);
        }
      } catch (error) {
        console.error({ error });
      } finally {
        setIsOrderLoading(false);
      }
    },
    [setOrders, setIsOrderLoading]
  );

  return fetchData;
};

export default useFetchOrders;
