import { useEffect, useState, useContext } from 'react';
import { Route, Routes } from 'react-router-dom';
import ApiKeys from './components/apiKeys';
import Webhooks from './components/webhooks';
import Account from './components/account';
import Orders from './components/orders';
import Order from './components/order';
import PlaceOrder from './components/placeOrder';
import OrderPlaced from './components/orderPlaced';
import Devices from './components/devices';
import Device from './components/device';
import { get, post } from 'aws-amplify/api';
import { fetchAuthSession } from 'aws-amplify/auth';
import { withAuthenticator, useAuthenticator } from '@aws-amplify/ui-react';
import { Amplify } from 'aws-amplify';
import config from './amplifyconfiguration.json';
import './App.css';
import Header from './mui-components/Header';
import { LicenseInfo } from '@mui/x-license';
import LoginHeader from './mui-components/LoginHeader';
import NotFound from './mui-components/NotFound';
import { useGlobalContext } from './context/GlobalStore';
import ContactSupport from './components/ContactSupport';
import { useNavigate } from 'react-router-dom';
import LoadingBackdrop from './mui-components/LoadingBackdrop';

LicenseInfo.setLicenseKey(
  'f788be76a03389674faef915ba578aeeTz04Nzc3MSxFPTE3NDM3OTczOTMwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI='
);
Amplify.configure(config);
const loginComponents = {
  hideSignUp: true,
  components: {
    Header: LoginHeader,
  },
};
function App() {
  const { user, signOut } = useAuthenticator((context) => [context.user]);
  const {
    state,
    setToken,
    setApiKey,
    setAccount,
    setIsDeviceLoading,
    setIsOrderLoading,
    setDevices,
    setOrders,
    setIsloading,
  } = useGlobalContext();
  const navigate = useNavigate();

  useEffect(() => {
    getSession();
  }, []);

  async function getSession() {
    setIsloading(false);
    try {
      const { tokens, credentials, identityId, userSub } =
        await fetchAuthSession();
      const { idToken, accessToken } = tokens;
      setToken(accessToken);
      // getAccount(accessToken);
      const account_id = await getAccount(accessToken);
      getDevices(accessToken, account_id);
      getOrders(accessToken, account_id);
    } catch (error) {
      console.log('error', error);
      navigate('/contact-support');
      setIsloading(false);
    }
  }

  async function getAccount(accessToken) {
    try {
      const restOperation = post({
        apiName: 'pylo',
        path: '/pylo/account',
        options: {
          body: {
            access_token: accessToken.toString(),
          },
        },
      });
      const { body } = await restOperation.response;
      const response = await body.json();
      setAccount(response);
      setApiKey({
        name: response.pylo_accounts[0].name,
        key: response.pylo_accounts[0].api_key,
      });

      return response.pylo_accounts[0]['id'];
    } catch (error) {
      console.log('error', error);
      navigate('/contact-support');
      setIsloading(false);
    }
  }

  async function getDevices(accessToken, accountId) {
    try {
      const restOperation = post({
        apiName: 'pylo',
        path: '/pylo/devices',
        options: {
          body: {
            access_token: accessToken.toString(),
            account_id: accountId,
          },
        },
      });
      const { body } = await restOperation.response;
      const response = await body.json();
      if (response) {
        setDevices(response);
      }
    } catch (error) {
      console.log({ error });
    } finally {
      setIsDeviceLoading(false);
    }
  }

  async function getOrders(accessToken, accountId) {
    try {
      const restOperation = get({
        apiName: 'pylo',
        path: '/pylo/orders',
        options: {
          queryParams: {
            access_token: accessToken.toString(),
            account_id: accountId,
          },
        },
      });
      const { body } = await restOperation.response;
      const response = await body.json();
      if (response) {
        const sorted = sortedOrders(response);
        setOrders(sorted);
      }
    } catch (error) {
      console.error({ error });
    } finally {
      setIsOrderLoading(false);
    }
  }

  const sortedOrders = (responseData) => {
    const sortedOrders = [];
    const dates = new Set(responseData.map((o) => o.date_placed));
    const sortedDates = Array.from(dates).sort().reverse();
    sortedDates.forEach((d) => {
      responseData
        .filter((o) => o.date_placed === d)
        .forEach((o) => {
          sortedOrders.push(o);
        });
    });
    return sortedOrders;
  };
  const signOutAndRedirect = () => {
    signOut();
    navigate('/');
  };

  useEffect(() => {
    // if both vals are true then toggle isloading
    if (!state.isDeviceLoading && !state.isOrderLoading) {
      setIsloading(false);
    }
  }, [state.isDeviceLoading, state.isOrderLoading]);

  return (
    <div className="App">
      <LoadingBackdrop />
      <Header signOut={signOutAndRedirect} />
      <Routes>
        <Route exact path="/contact-support" element={<ContactSupport />} />
        <Route
          exact
          path="/orders"
          element={
            <Orders
              getOrders={getOrders}
            />
          }
        />
        <Route path="/orders/:id" element={<Order />} />
        <Route
          exact
          path="/placeOrder"
          element={<PlaceOrder setOrders={setOrders} />}
        />
        <Route path="/placeOrder/:id" element={<OrderPlaced />} />
        <Route exact path="/" element={<Devices getDevices={getDevices} />} />
        <Route path="/devices/:id" element={<Device />} />
        {/* <Route path="/readings/:id" element={<Readings token={token} />} /> */}
        <Route exact path="/apiKeys" element={<ApiKeys />} />
        <Route exact path="/webhooks" element={<Webhooks />} />
        <Route
          exact
          path="/account"
          element={<Account getAccount={getAccount} />}
        />
        <Route path="*" exact={true} element={<NotFound />} />
      </Routes>
    </div>
  );
}

export default withAuthenticator(App, loginComponents);
