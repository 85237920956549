import { Autocomplete, TextField, Typography, Grid } from '@mui/material';
import { formAddress } from '../data/orderForm';

const FormAddress = (props) => {
  const { options, setAddress, onChange, errors, form } = props;
  return (
    <Grid container spacing={2}>
      {formAddress.map((item, i) => {
        if (i === 0 || i === 1) {
          return (
            <Grid item xs={i === 0 ? 8 : 4} key={item.name}>
              <Typography variant="overline" color="text.primary">
                {formAddress[i].label}:{' '}
              </Typography>
              {i === 0 ? (
                <Autocomplete
                  disablePortal
                  id="place-autocomplete"
                  options={options.map((opt) => opt.Text) || []}
                  size="small"
                  onChange={setAddress}
                  filterOptions={(options, state) => options}
                  getOptionKey={(option) =>
                    `opt-id-${option.Text}-${Math.random() * 100}`
                  }
                  value={form[item.name]}
                  isOptionEqualToValue={(option, value) => {
                    if (option?.Text) {
                      return option.Text.includes(value);
                    }
                  }}
                  freeSolo={true}
                  renderInput={(params) => (
                    <TextField
                      required
                      {...params}
                      name={formAddress[0].name}
                      onChange={(e) => onChange(e, 5)}
                    />
                  )}
                />
              ) : (
                <TextField
                  name={item.name}
                  fullWidth
                  size="small"
                  onChange={(e) => onChange(e, i)}
                  value={form[item.name] || ''}
                />
              )}
            </Grid>
          );
        } else {
          return (
            <Grid item key={item.name} sm={6}>
              <Typography variant="overline" color="text.primary">
                {item.label}:{' '}
              </Typography>
              <TextField
                required
                name={item.name}
                placeholder={item.label}
                fullWidth
                size="small"
                onChange={(e) => onChange(e, i)}
                error={errors[i]}
                value={form[item.name] || ''}
                helperText={errors[i] ? 'Incorrect entry.' : ''}
                InputProps={{
                  readOnly: item.name === 'ccode' ? true : false,
                }}
              />
            </Grid>
          );
        }
      })}
    </Grid>
  );
};

export default FormAddress;
