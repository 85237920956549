import { Stack, Typography } from '@mui/material';
import NoDataSVG from '../assets/no_data.svg';
export const NoRowsGrid = () => {
  return (
    <Stack
      height="100%"
      alignItems="center"
      justifyContent="center"
      spacing={4}
    >
      <img
        src={NoDataSVG}
        alt="No rows in DataGrid"
        width={300}
        style={{ opacity: 0.9 }}
      />
      <Typography variant="h4">No rows found</Typography>
    </Stack>
  );
};

export const NoResultsGrid = () => {
  return (
    <Stack height="100%" alignItems="center" justifyContent="center">
      <img src={NoDataSVG} alt="No rows in DataGrid" width={300} />
      <Typography variant="h4">Local filter returns no result</Typography>
    </Stack>
  );
};
