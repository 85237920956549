import { Typography, Box, FormControl } from '@mui/material';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { useGlobalContext } from '../context/GlobalStore';
import useFetchDevices from '../hooks/useFetchDevices';
import useFetchOrders from '../hooks/useFetchOrders';

const ToggleDatabase = () => {
  const { state, setApiKey, setIsDeviceLoading, setIsOrderLoading } =
    useGlobalContext();
  const fetchDevices = useFetchDevices();
  const fetchOrders = useFetchOrders();

  const handleChange = async (api_key) => {
    setIsOrderLoading(true);
    setIsDeviceLoading(true);

    const new_account = state.account.pylo_accounts.find(
      (a) => a.api_key === api_key
    );
    setApiKey({ name: new_account.name, key: api_key });
    fetchDevices(state.token, new_account['id']);
    fetchOrders(state.token, new_account['id']);
  };
  return (
    <Box display="flex" alignItems="center">
      <Typography variant="body1" mr={2}>
        App:
      </Typography>
      {!!state.account?.pylo_accounts?.length && (
        <FormControl sx={{ minWidth: 100 }} size="small">
          <Select
            labelId="apikey-select-label"
            id="apikey-select"
            value={state.apiKey.key}
            onChange={(event) => handleChange(event.target.value)}
          >
            {Object.entries(state.account.pylo_accounts).map(([key, val]) => (
              <MenuItem value={val['api_key']} key={key} size="small">
                {val.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    </Box>
  );
};

export default ToggleDatabase;
