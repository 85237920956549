import {
  Chip,
  Box,
  FormControl,
  Select,
  MenuItem,
  OutlinedInput,
} from '@mui/material';
import { styles } from './styles';
import PersonIcon from '@mui/icons-material/Person';
import { purple, green } from '@mui/material/colors';

const MultiSelectChip = (props) => {
  const { user, item, handleChange } = props;

  const getStyles = (name, val) => {
    return {
      fontWeight: user[name].indexOf(val) === -1 ? 400 : 600,
    };
  };
  return (
    <FormControl fullWidth size="small" sx={styles.select} required>
      <Select
        name={item.name}
        id={`multi-select-${item.name}`}
        input={<OutlinedInput />}
        value={user[item.name] || ''}
        onChange={handleChange}
        renderValue={(selected) => (
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
            {selected.map((value) => {
              let color;
              if (item.name === 'role') {
                color = value === 'Admin' ? 'error' : 'info';
                return (
                  <Chip
                    key={value}
                    label={value}
                    color={color}
                    icon={<PersonIcon />}
                    sx={{ textTransform: 'capitalize' }}
                  />
                );
              } else {
                color = value === 'Dev' ? purple[100] : green[100];
                const label = value === 'Dev' ? 'Development' : 'Production';
                return (
                  <Chip
                    key={value}
                    label={label}
                    sx={{ backgroundColor: color, textTransform: 'capitalize' }}
                  />
                );
              }
            })}
          </Box>
        )}
      >
        {item.list.map((li) => {
          return (
            <MenuItem key={li} value={li} style={getStyles(item.name, li)}>
              {li}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

export default MultiSelectChip;
