import { useParams } from 'react-router';
import { useLocation } from 'react-router-dom';
import Layout from '../mui-components/Layout';
import { Grid, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import moment from 'moment';
import Readings from './readings';
import Divider from '@mui/material/Divider';
import { timeFormat } from '../data/formats';
import { pageTitles } from '../data/pageTitles';
import { useGlobalContext } from '../context/GlobalStore';
export default function Device() {
  document.title = pageTitles.device;
  const { state } = useGlobalContext();
  let location = useLocation();
  const { id } = useParams();
  const [deviceData, setDeviceData] = useState([]);

  useEffect(() => {
    if (state.devices.length > 0) {
      const device = state.devices.find((d) => d.device_imei === id);
      const data = [
        { label: 'Device Id', val: device.device_id },
        { label: 'Device Imei', val: device.device_imei },
        { label: 'Device Sim', val: device.device_sim },
        { label: 'Device Type', val: device.device_type },
        { label: 'Device Model', val: device.device_model },
        {
          label: 'First Reading',
          val: device.first_measurement_timestamp ? moment(device.first_measurement_timestamp).format(timeFormat) : '',
        },
        {
          label: 'Last Reading',
          val: device.last_measurement_timestamp ? moment(device.last_measurement_timestamp).format(timeFormat) : '',
        },
        { label: 'Billing Status', val: device.billing_status },
        { label: 'Patient Id', val: device.patient_id },
        { label: 'Practice Id', val: device.practice_id },
      ];
      setDeviceData(data);
    }
  }, [state.devices]);

  return (
    <Layout title="Device Details">
      <Grid container spacing={2} mt={1} mb={4}>
        {deviceData.length > 0 &&
          deviceData.map((item, i) => {
            return (
              <Grid
                item
                key={item.label}
                xs={12}
                sm={6}
                xl={4}
                sx={{ display: 'flex' }}
              >
                <Typography
                  variant="body1"
                  sx={{ color: 'muted', width: 120 }}
                  mr={1}
                >
                  {item.label}:{' '}
                </Typography>
                <Typography variant="body1" sx={{ fontWeight: 600 }}>
                  {item.val}
                </Typography>
              </Grid>
            );
          })}
      </Grid>
      <Divider />
      <Readings
        token={state.token}
        id={id}
        accountId={location.state.account_id}
      />
    </Layout>
  );
}
