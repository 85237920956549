import { useEffect, useState } from 'react';
import { post } from 'aws-amplify/api';
import { pageTitles } from '../data/pageTitles';
import Layout from '../mui-components/Layout';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import CreateFirstWebhook from '../mui-components/Webhooks/CreateFirstWebhook';
import Webhook from '../mui-components/Webhooks/Webhook';
import { Box, Stack } from '@mui/material';
import { useGlobalContext } from '../context/GlobalStore';
import ToggleDatabase from '../mui-components/ToggleDatabase';
import { isArray } from 'chart.js/helpers';

export default function Webhooks() {
  document.title = pageTitles.webhooks;

  const { state, setAccount } = useGlobalContext();
  const [apiKey, setApiKey] = useState(
    !!state?.account ? state?.account?.pylo_accounts[0]?.api_key : ''
  );
  const [webhook, setWebhook] = useState();
  const [url, setUrl] = useState('');

  useEffect(() => {
    // set webhook url
    if (state.account) {
      setWebhook(state.account?.webhooks[0]);
    }
  }, []);

  async function rotateUrl() {
    try {
      const accountId = state?.account?.pylo_accounts?.find(
        (a) => a.api_key === apiKey
      )['id'];
      const restOperation = post({
        apiName: 'pylo',
        path: '/pylo/webhooks',
        options: {
          body: {
            access_token: state.token.toString(),
            account_id: accountId,
            new_url: url,
          },
        },
      });

      const { body } = await restOperation.response;
      const response = await body.json();
      const copy = { ...state.account };
      const wh = copy.webhooks.find((w) => w.customer_id === accountId);
      wh['url'] = url;
      setAccount(copy);
    } catch (e) {
      console.error('Error rotating url: ', JSON.parse(e.response.body));
    }
  }

  return (
    <Layout title="Webhook">
      <Stack gap={3}>
        <ToggleDatabase />
        <Box alignItems="center" display="flex">
          Webhook URL:&nbsp;&nbsp;
          <code
            style={{
              background: 'black',
              border: '1px solid gray',
              padding: '7px 8px',
              color: 'lightgray',
              borderRadius: '5px',
            }}
          >
            {
            (state && 
            state.account && 
            isArray(state.account.webhooks) && 
            state.account.webhooks.find((w) => w.customer_id === state.account.pylo_accounts.find((a) => a.api_key === state.apiKey.key)['id'])) ? 
            (<span>{state.account.webhooks.find((w) => w.customer_id === state.account.pylo_accounts.find((a) => a.api_key === state.apiKey.key)['id']).url}</span>) : 
            (<span>N/A</span>)
            }
          </code>
        </Box>
        {/* {state?.account?.webhook_url ? (
          <Webhook url={state.account.webhook_url} />
        ) : (
          <CreateFirstWebhook />
        )} */}
      </Stack>
    </Layout>
  );
}
