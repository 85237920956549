import { Button, Stack, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';

const NotFound = () => {
  const navigate = useNavigate();
  const goToHome = () => {
    navigate('/');
  };
  return (
    <Stack
      justifyContent="center"
      alignItems="center"
      spacing={4}
      sx={{ width: '100%', height: 'calc(100vh - 100px)' }}
    >
      <WarningAmberIcon color="warning" sx={{ width: 100, height: 100 }} />
      {/* <a href="https://storyset.com/web">Web illustrations by Storyset</a> */}
      <Typography variant="h1">404 Not Found</Typography>
      <Typography variant="body1">
        We're sorry, the page you were looking for is unavailable.
      </Typography>

      <Button
        onClick={goToHome}
        variant="contained"
        sx={{ textTransform: 'none' }}
      >
        Go to dashboard
      </Button>
    </Stack>
  );
};

export default NotFound;
