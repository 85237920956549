import { TableHead, TableRow, TableCell } from '@mui/material';

const UserTableHead = (props) => {
  return (
    <TableHead>
      <TableRow>
        {props.list.map((item) => {
          return <TableCell key={`user-table-head-${item}`}>{item}</TableCell>;
        })}
      </TableRow>
    </TableHead>
  );
};

export default UserTableHead;
