import { Stack, CircularProgress, Typography } from '@mui/material';

const Loading = () => {
  return (
    <Stack
      justifyContent="center"
      alignItems="center"
      sx={{ height: '100%', width: '100%' }}
    >
      <CircularProgress color="primary" size={100} />
      <Typography color="primary" mt={6} variant="h6">
        Loading...
      </Typography>
    </Stack>
  );
};

export default Loading;
