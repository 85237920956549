import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  DialogActions,
  Box,
  DialogContentText,
} from '@mui/material';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import Loading from '../../mui-components/Loading';
import { styles } from './styles';

const AlertModal = (props) => {
  const { open, handleClose, deleteUser, userToDelete, isLoading } = props;
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="lg"
    >
      {isLoading ? (
        <DialogContent sx={styles.loadingBox}>
          <Loading />
        </DialogContent>
      ) : (
        <>
          <DialogTitle id="alert-dialog-title">
            Are you sure you want to delete{' '}
            <strong>{userToDelete?.user_name}</strong> -{' '}
            <strong>{userToDelete?.email}</strong>?
          </DialogTitle>
          <DialogContent>
            <Box display="flex" justifyContent="center">
              <ReportProblemIcon
                sx={{ width: 100, height: 100 }}
                color="warning"
              />
            </Box>
            <DialogContentText id="alert-dialog-description">
              This action cannot be undone and the user will not be notified of
              this action.
            </DialogContentText>
          </DialogContent>
          <DialogActions sx={{ padding: 3 }}>
            <Button onClick={handleClose} variant="outlined">
              Cancel
            </Button>
            <Button
              onClick={deleteUser}
              variant="contained"
              color="error"
              autoFocus
            >
              Agree and Delete
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};

export default AlertModal;
