import { Stack, Typography, Button } from '@mui/material';
import WarningIcon from '@mui/icons-material/Warning';
import { useNavigate } from 'react-router-dom';

const ErrorGenericFallback = (props) => {
  const { title, msg, link, linkText } = props;
  const navigate = useNavigate();
  return (
    <Stack
      alignItems="center"
      sx={{
        height: '100%',
        maxWidth: 600,
        marginLeft: 'auto',
        marginRight: 'auto',
      }}
      spacing={3}
      mt={6}
    >
      <WarningIcon color="warning" sx={{ fontSize: 60 }} />
      <Typography variant="h2">
        {title !== '' ? title : 'Oops sth went wrong.'}
      </Typography>
      <Typography variant="body1">{msg}</Typography>
      {linkText !== '' && (
        <Button variant="contained" onClick={() => navigate(link)}>
          {linkText}
        </Button>
      )}
    </Stack>
  );
};

export default ErrorGenericFallback;
