import { TableCell, IconButton } from '@mui/material';
// import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { useGlobalContext } from '../../context/GlobalStore';

const ActionsCell = (props) => {
  const { deleteUser, viewUser, editUser, user } = props;
  // edit user disabled for now
  const { state } = useGlobalContext();
  // const icons = [<RemoveRedEyeIcon />];
  const icons = [];
  const policyKeys = Object.keys(
    state.account.authenticated_user.policy_document
  );

  const isAdmin = policyKeys.map((key) =>
    state.account.authenticated_user.policy_document[key].includes(
      'admin:CreateUser'
    )
  );
  const loggedInUserEmail = state.account.authenticated_user.email;

  const labels = ['View'];
  const funcs = [viewUser];

  if (isAdmin.includes(true) && user.email !== loggedInUserEmail) {
    labels.unshift('Delete');
    funcs.unshift(deleteUser);
    icons.unshift(<DeleteIcon />);
  }

  return (
    <TableCell>
      {icons.map((icon, i) => {
        return (
          <IconButton
            key={labels[i]}
            aria-label={labels[i]}
            mr={2}
            onClick={funcs[i]}
          >
            {icon}
          </IconButton>
        );
      })}
    </TableCell>
  );
};

export default ActionsCell;
