export const pageTitles = {
  placeOrder: 'Pylo Management Portal - Place Order',
  account: 'Pylo Management Portal - Account Details',
  apiKeys: 'Pylo Management Portal - API keys',
  device: 'Pylo Management Portal - Device',
  devices: 'Pylo Management Portal - Devices',
  home: 'Pylo Management Portal - Home',
  order: 'Pylo Management Portal - Order',
  orderPlaced: 'Pylo Management Portal - Order Placed',
  orders: 'Pylo Management Portal - Orders',
  webhooks: 'Pylo Management Portal - Webhooks',
};
